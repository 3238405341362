
import { Component, Vue } from 'vue-property-decorator'
import DevicesDataTable from '@/components/DeviceComponents/DevicesDataTable.vue'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import Store from '@/store'
import moment from 'moment'

@Component({
  components: {
    DevicesDataTable,
    SearchBox
  }
})
export default class devices extends Vue {
  public search = ''
  public pending_devices = false
  public items = [{ title: 'Todos los dispositivos' }]
  public tab_items = ['Con productos asociados', 'Sin productos asociados']
  public tabs = null

  mounted() {
    this.tabs = Store.state.devices.search_type
    this.fetch_status()
  }

  public fetch_status() {
    const search_type = Store.state.devices.search_type
    switch (search_type) {
      case 0:
        this.pending_devices = false
        break

      case 1:
        this.pending_devices = true
        break

      default:
        this.pending_devices = false
        break
    }
  }

  public switch_tabs(type: number) {
    if (type == 1) {
      Store.commit('devices/mutate', {
        property: 'search_type',
        with: 1
      })
      Store.dispatch('devices/fetchRoot')
    } else {
      Store.commit('devices/mutate', {
        property: 'search_type',
        with: 0
      })
      Store.dispatch('devices/fetchRoot')
    }
  }

  get date() {
    return moment().format('DD_MM_YYYY')
  }
  get company_name() {
    return Store.state.session.company[0].name
  }

  async data_download() {
    await Store.dispatch('products/exportDiagnostics')
  }
}
