<template>
  <v-snackbar
    bottom
    right
    :color="$store.state.system.snackBar.color"
    :value="$store.state.system.snackBar.show"
    :timeout="$store.state.system.snackBar.timeout"
    @input="
      $store.commit('system/mutate', {
        property: 'snackBar.show',
        with: $event
      })
    "
  >
    <div>
      {{ $store.state.system.snackBar.message }}
    </div>
    <v-progress-linear
      class="mt-2"
      v-if="$store.state.system.snackBar.progress"
      color="white"
      :value="progress"
    ></v-progress-linear>

    <template v-slot:action="{ attrs }">
      <v-btn
        :href="$store.state.system.snackBar.download"
        v-if="$store.state.system.snackBar.download"
        download
        class="menu__button mr-2"
        color="secondary"
        dark
        small
      >
        Descargar <v-icon right dark> mdi-file-download-outline </v-icon>
      </v-btn>
      <v-btn
        dark
        text
        v-bind="attrs"
        small
        @click="
          $store.commit('system/mutate', {
            property: 'snackBar.show',
            with: false
          })
        "
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      progress: 5,
      intervalId: null
    }
  },
  methods: {
    startProgress() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      const duration = 10000
      const increment = 1000
      const steps = duration / increment
      const stepDuration = duration / steps
      this.progress = 15
      this.intervalId = setInterval(() => {
        if (this.progress < 95) {
          this.progress += (100 - 15) / steps
        } else {
          clearInterval(this.intervalId)
          this.intervalId = null
        }
      }, stepDuration)
    }
  },
  computed: {
    show: {
      get() {
        return this.$store.state.system.snackBar.show
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.startProgress()
      }
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
</script>
