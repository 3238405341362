var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-snackbar',{attrs:{"bottom":"","right":"","color":_vm.$store.state.system.snackBar.color,"value":_vm.$store.state.system.snackBar.show,"timeout":_vm.$store.state.system.snackBar.timeout},on:{"input":function($event){return _vm.$store.commit('system/mutate', {
      property: 'snackBar.show',
      with: $event
    })}},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [(_vm.$store.state.system.snackBar.download)?_c('v-btn',{staticClass:"menu__button mr-2",attrs:{"href":_vm.$store.state.system.snackBar.download,"download":"","color":"secondary","dark":"","small":""}},[_vm._v(" Descargar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-download-outline ")])],1):_vm._e(),_c('v-btn',_vm._b({attrs:{"dark":"","text":"","small":""},on:{"click":function($event){return _vm.$store.commit('system/mutate', {
          property: 'snackBar.show',
          with: false
        })}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.system.snackBar.message)+" ")]),(_vm.$store.state.system.snackBar.progress)?_c('v-progress-linear',{staticClass:"mt-2",attrs:{"color":"white","value":_vm.progress}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }