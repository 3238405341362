import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Store from '@/store'
import moment from 'moment'

Vue.use(VueRouter)

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/Auth.vue')
  },
  {
    path: '',
    redirect: '/tablero'
  },
  {
    path: '*',
    redirect: '/tablero'
  },
  {
    path: '/tablero',
    component: () => import('@/pages/dashboard/index.vue'),
    meta: {
      title: () => 'Tablero'
    }
  },
  {
    name: 'alerts.index',
    path: '/alerts',
    component: () => import('@/pages/alerts/index.vue'),
    beforeEnter(to, from, next) {
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Alertas'
      })
      Store.dispatch('alerts/fetchTable', 'pending')
      Store.dispatch('alerts/fetchTable', 'approved')
      Store.dispatch('alerts/fetchTable', 'rejected')
      next()
    },
    meta: {
      title: () => 'Alertas'
    }
  },
  {
    name: 'alerts.details',
    path: '/alerts/details/:id',
    component: () => import('@/pages/alerts/detail.vue'),
    meta: {
      title: () => 'Detalle alerta'
    }
  },
  {
    name: 'alerts.details.full',
    path: '/alerts/details/:id/closed',
    component: () => import('@/pages/alerts/detail-full.vue'),
    meta: {
      title: () => 'Detalle alerta'
    }
  },
  {
    path: '/productos',
    component: () => import('@/pages/products/default.vue'),
    name: 'Productos',
    async beforeEnter(to, from, next) {
      Store.commit('products/mutate', {
        property: 'root.status',
        with: 'fetching'
      })
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Productos'
      })
      Store.commit('products/mutate', {
        property: 'companyId',
        with: Store.state.session.company[0].id
      })
      //Store.dispatch('brands/fetchRoot', () => true)
      await Store.dispatch('openClient/fetchConfig', () => true)
      if (Store.state.clients.status !== 'fulfilled') Store.dispatch('clients/fetchRoot', () => true)
      Store.dispatch('products/fetchRoot', () => true)

      next()
    }
  },
  {
    path: '/productos/detalle/:id',
    name: 'Detalle de productos',
    component: () => import('@/pages/products/_id.vue'),
    async beforeEnter(from, to, next) {
      Store.dispatch('openClient/fetchConfig', () => true)
      next()
    }
  },
  {
    path: '/devices/all',
    name: 'Dispositivos',
    component: () => import('@/pages/devices/default.vue'),
    beforeEnter(to, from, next) {
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Dispositivos'
      })
      Store.dispatch('devices/fetchRoot', () => true)
      next()
    }
  },
  {
    path: '/devices/search',
    name: 'Busqueda de dispositivos',
    component: () => import('@/pages/devices/search.vue')
  },
  {
    path: '/devices/:id',
    name: 'Detalle de Dispositivo',
    component: () => import('@/pages/devices/_trustId.vue'),
    async beforeEnter(from, to, next) {
      Store.dispatch('openClient/fetchConfig', () => true)
      next()
    }
  },
  {
    path: '/config/companies',
    name: 'Compañías',
    component: () => import('@/pages/companies/Companies.vue'),
    beforeEnter(to, from, next) {
      Store.commit('companies/resetState')
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Compañías'
      })
      Store.dispatch('companies/fetchRoot', () => true)
      next()
    }
  },
  {
    path: '/config/clients',
    name: 'Clients',
    component: () => import('@/pages/clients/Clients.vue'),
    beforeEnter(to, from, next) {
      Store.commit('clients/resetState')
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Clientes'
      })
      if (Store.state.clients.status !== 'fulfilled') Store.dispatch('clients/fetchRoot', () => true)
      next()
    }
  },
  {
    path: '/config/users',
    name: 'Usuarios',
    component: () => import('@/pages/users/Users.vue'),
    beforeEnter(to, from, next) {
      Store.commit('users/resetState')
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Usuarios'
      })
      Store.commit('users/mutate', {
        property: 'companyUid',
        with: Store.state.session.company[0].company_uid
      })
      Store.dispatch('users/fetchRoot', () => true)
      if (Store.state.clients.status !== 'fulfilled') Store.dispatch('clients/fetchRoot', () => true)
      next()
    }
  },
  {
    path: '/diagnostics/parameters',
    name: 'Parámetros',
    component: () => import('@/pages/diagnostics/openDiagnostic.vue'),
    async beforeEnter(to, from, next) {
      Store.commit('openDiagnostic/resetState')
      await Store.commit('openDiagnostic/mutate', {
        property: 'client.company_id',
        with: Store.state.session.company[0].id
      })
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Parametros'
      })
      if (Store.state.clients.status !== 'fulfilled') Store.dispatch('clients/fetchRoot', () => true)
      Store.dispatch('openDiagnostic/fetchParameters', () => true)
      next()
    }
  },
  {
    path: '/diagnostics/devices',
    name: 'Diagnósticos por dispositivo',
    component: () => import('@/pages/diagnostics/index.vue'),
    async beforeEnter(to, from, next) {
      Store.commit('navigationDrawer/mutate', {
        property: 'module',
        with: 'Diagnósticos por dispositivo'
      })
      if (Store.state.phones.root.status !== 'fulfilled') Store.dispatch('phones/fetchRoot', () => true)
      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
  if (!Store.state.system.snackBar.persist) {
    Store.commit('system/mutate', {
      property: 'snackBar.show',
      with: false
    })
  }
  if (moment().isAfter(Store.state.session.deadline)) {
    await Store.commit('session/resetState')
    window.location.replace(Store.getters['system/fullUrlAuth'])
  }

  if (to.name !== 'Auth') {
    if (Store.state.session.auth === false) {
      await Store.commit('session/resetState')
      window.location.replace(Store.getters['system/fullUrlAuth'])
    }
  }
  if (to.name == 'Auth') {
    if (Store.state.session.auth === true) {
      window.history.forward()
    }
  }
  next()
})
export default router
